import { ChangeDetectionStrategy, Component, HostListener, Inject, Input, OnInit } from '@angular/core';
import { trigger, state, transition, style, animate } from '@angular/animations';
import { DOCUMENT } from '@angular/common';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'pbg-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('fade',
      [
        state('void', style({opacity: 0})),
        transition(':enter', [animate(300)]),
        transition(':leave', [animate(500)]),
      ]
    )]
})
export class NavComponent implements OnInit {

  staticURL = environment.staticURL;

  menuActive = false;

  shrinkMenu: boolean;

  mobileSticky: boolean;

  innerWidth: number;

  constructor(@Inject(DOCUMENT) document) {
    this.shrinkMenu = false;
    this.innerWidth = window.innerWidth;
  }

  get logoWidth(): number {
    if (this.innerWidth <= 768) {
      return 75;
    } else {
      return this.shrinkMenu ? 75 : 125;
    }
  }

  ngOnInit() {
    this.mobileSticky = window.innerWidth <= 768 && window.pageYOffset > 200;
  }

  menuToggle($event) {
    this.menuActive = !this.menuActive;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll(e) {
    this.shrinkMenu = window.pageYOffset >= 200;
    this.mobileSticky = window.innerWidth <= 768 && window.pageYOffset >= 200;
  }
}
