<!-- Header -->
<header id="headerwrap" class="backstretched fullheight">
  <div class="container-fluid-md fullheight d-flex d-md-block">
    <div class="row h-100 my-auto">
      <div class="col my-auto">
        <div class="row">
          <div class="col-sm-1 pull-left text-center slider-control my-auto">
            <a href="#" class="prev-bs-slide vertical-center wow fadeInLeft" data-wow-delay="0.8s">
              <i class="fa fa-long-arrow-left"></i></a>
          </div>
          <div class="intro-text text-center smoothie col-sm-10 mx-auto my-auto">
            <div class="wow fadeIn heading-font" data-wow-delay="0.8s">
              <img [src]="staticURL + 'assets/img/header-greeting.png'" class="img-fluid"
                   alt="Casual Friendly Local Restaurant & Bar">
            </div>
          </div>
          <div class="col-sm-1 pull-right text-center slider-control match-height my-auto">
            <a href="#" class="next-bs-slide vertical-center wow fadeInRight" data-wow-delay="0.8s">
              <i class="fa fa-long-arrow-right"></i></a>
          </div>
        </div>
      </div>
    </div>
  </div>
</header>
