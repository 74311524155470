import { Component, OnInit } from '@angular/core';
import { GraphqlService } from '../../graphql/graphql.service';
import { MenuNodeEdge } from '../../../generated/graphql';
import { NgxMasonryOptions } from 'ngx-masonry';

@Component({
  selector: 'pbg-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit {

  menus: MenuNodeEdge[];

  loading = false;

  updateMasonryLayout: boolean;

  public options: NgxMasonryOptions = {};

  constructor(private  graphqlService: GraphqlService) {
  }

  ngOnInit() {
    this.graphqlService.getMenu()
      .valueChanges
      .subscribe(({data, loading}) => {
        this.loading = loading;
        this.menus = data.menus.edges;
        this.updateMasonryLayout = true;
      });
  }
}
