import { Component, Inject, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'pbg-special-features',
  templateUrl: './special-features.component.html',
  styleUrls: ['./special-features.component.css']
})
export class SpecialFeaturesComponent implements OnInit {

  staticURL = environment.staticURL;

  constructor(@Inject(DOCUMENT) private document: Document) {
  }

  ngOnInit() {
  }

  setElementBorders(selected: string) {
    Array.from(this.document.getElementsByClassName('features'))
      .forEach(x => x.classList.remove('border'));

    const liElement = this.document.getElementById(`${selected}`);
    liElement.classList.toggle('border');
  }


}
