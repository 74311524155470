import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { NgwWowService } from 'ngx-wow';
import { GraphqlService } from './graphql/graphql.service';
import { CompanyInfoNode } from '../generated/graphql';
import { PageScrollService } from 'ngx-page-scroll-core';
import { DOCUMENT } from '@angular/common';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';

@Component({
  selector: 'pbg-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit  {
  title = 'frontend';

  companyInfo: CompanyInfoNode;

  loading = false;

  // @ViewChild('updateSwal', {static: true}) private updateSwal: SwalComponent;

  constructor(private wowService: NgwWowService,
              private  graphqlService: GraphqlService,
              private pageScrollService: PageScrollService,
              @Inject(DOCUMENT) private document: any) {
    this.wowService.init();

    this.graphqlService.getCompanyInfo()
      .valueChanges
      .subscribe(({data, loading}) => {
        this.loading = loading;
        this.companyInfo = data.companyInfo;
      });

    const anchor = this.document.URL.split('/').pop();

    if (anchor.includes('#')) {
      setTimeout(() => this.pageScrollService.scroll({
        document: this.document,
        scrollTarget: anchor,
      }), 3000);
    }
  }

  ngOnInit(): void {
    // this.updateSwal.fire();
  }
}
