<div class="container-fluid">
  <div class="row">
    <div class="col-sm-6 nopadding-lr dark-wrapper opaqued background-cover left-half bottom-half"
         [style.backgroundPosition]="'center'"
         [style.backgroundImage]="'url(' + staticURL + 'assets/img/bg/bg13.jpg)'">
      <div class="dark-opaqued-half section-inner pad-sides-60 match-height" data-mh="promo-inner">
        <h3 class="mb50">Love <span class="theme-accent-color">Ribs?</span></h3>
        <p class="lead mb50">We do too. We offer young tender Ribs flame broiled over real hardwood charcoal with our
          special BBQ sauce. Try our famous BBQ Ribs with a choice of a regular side. Add Grilled Lobster or Grilled
          Shrimp to make for a mouthwatering Surf n Turf feast. Enjoy a delicious meal guaranteed to satisfy your taste
          buds! </p>
        <div class="spacer-180"></div>
        <p class="mt30"><a pageScroll href="#menu" class="btn btn-primary btn-red page-scroll">View Menu</a></p>
      </div>
    </div>

    <div class="col-sm-6 nopadding-lr dark-wrapper opaqued background-cover right-half top-half"
         [style.backgroundPosition]="'center'"
         [style.backgroundImage]="'url(' + staticURL + 'assets/img/bg/bg14.jpg)'">
      <div class="dark-opaqued-half section-inner pad-sides-60 match-height text-right" data-mh="promo-inner">
        <h3 class="mb50">Sports <span class="theme-accent-color">Fans</span></h3>
        <p class="lead mb50">Island life restricting you from making it to the stadium? We are the perfect spot to watch your favourite sports games
          live. We have several large screen TVs, great vibes and delicious food. Immerse yourself in the action with
          friends, family, food and drinks. We look forward to seeing you!</p>
        <br>
        <div class="spacer-180"></div>
        <p class="mt30"><a pageScroll href="#contact" class="btn btn-primary btn-white page-scroll">Book Now</a></p>
      </div>
    </div>
  </div>
</div>
