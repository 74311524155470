<pbg-nav></pbg-nav>

<pbg-header></pbg-header>

<pbg-about></pbg-about>

<pbg-call-to-action-section></pbg-call-to-action-section>

<pbg-special-features></pbg-special-features>

<pbg-menu></pbg-menu>

<!--<pbg-photos></pbg-photos>-->

<!--<pbg-customer-reviews></pbg-customer-reviews>-->

<pbg-contact-info [companyInfo]="companyInfo"></pbg-contact-info>

<pbg-map></pbg-map>

<pbg-footer [companyInfo]="companyInfo"></pbg-footer>

<div id="bottom-frame"></div>

<a href="#" id="back-to-top"><i class="fa fa-long-arrow-up"></i></a>

<!--<swal title="" icon="warning" [confirmButtonColor]="'#f22613'" #updateSwal>-->
<!--  <div *swalPortal class="alert alert-warning">-->
<!--    <p>It is with a heavy heart that Peppers is now closed until further notice in support of the effort to keep-->
<!--      everyone safe at home including our valued employees. Our Jerk Chicken and BBQ Ribs will taste even better when we-->
<!--      have put this behind us. Keep safe everyone.</p>-->
<!--    <p>Please note we are open for curbside pickup and delivery only. Please call us at <a href="tel:+13459433000">943-3000</a>-->
<!--      or order online at <a href="https://islandgrub.ky">islandgrub.ky</a>.</p>-->
<!--  </div>-->
<!--</swal>-->
