import { Component, Input, OnInit } from '@angular/core';
import { faTwitter } from '@fortawesome/free-brands-svg-icons/faTwitter';
import { faInstagram } from '@fortawesome/free-brands-svg-icons/faInstagram';
import { faFoursquare } from '@fortawesome/free-brands-svg-icons/faFoursquare';
import { faFacebookF } from '@fortawesome/free-brands-svg-icons/faFacebookF';
import { faGoogle } from '@fortawesome/free-brands-svg-icons/faGoogle';
import { CompanyInfoNode } from '../../../generated/graphql';

@Component({
  selector: 'pbg-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  @Input() companyInfo: CompanyInfoNode

  year = new Date().getFullYear();

  faGoogle = faGoogle;

  faFacebookF = faFacebookF;

  faTwitter = faTwitter;

  faInstagram = faInstagram;

  faFoursquare = faFoursquare;

  constructor() {
  }

  ngOnInit() {
  }

}
