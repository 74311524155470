import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { ContactForm } from '../forms/ContactForm';
import { AjaxResponse } from '../interfaces/ajax-response';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class FormsService {

  contactUrl = `${environment.formsURL}/contact/`;

  httpOptions = {};

  constructor(private http: HttpClient,
              private cookieService: CookieService,
              @Inject(DOCUMENT) private document: Document) {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
        'X-CSRFToken': this.getCSFRToken(),
      })
    };
  }

  getCSFRToken() {
    return this.cookieService.get('csrftoken') || (this.document.querySelector('[name="csrfmiddlewaretoken"]')[0]).value;
  }

  /** POST: send the contact form */
  sendContactForm(contactForm: ContactForm): Observable<AjaxResponse | ContactForm> {
    const serializedForm = JSON.stringify(contactForm);

    return this.http.post<AjaxResponse>(this.contactUrl, serializedForm, this.httpOptions)
      .pipe(
        catchError(this.handleError('ContactForm', contactForm))
      );
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      console.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
