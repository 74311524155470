import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';

import { WatchQueryOptions } from 'apollo-client';
import { GET_COMPANY_INFO, GET_MENU } from './queries';
import { Query } from '../../generated/graphql';

@Injectable({
  providedIn: 'root'
})
export class GraphqlService {

  constructor(private apollo: Apollo) {
  }

  getCompanyInfo() {
    return this.apollo.watchQuery<Query>({query: GET_COMPANY_INFO.gql} as WatchQueryOptions);
  }

  getMenu() {
    return this.apollo.watchQuery<Query>({query: GET_MENU.gql} as WatchQueryOptions);
  }
}
