import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { NavComponent } from './components/nav/nav.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { AboutComponent } from './components/about/about.component';
import { CallToActionSectionComponent } from './components/call-to-action-section/call-to-action-section.component';
import { MenuComponent } from './components/menu/menu.component';
import { CustomerReviewsComponent } from './components/customer-reviews/customer-reviews.component';
import { ContactInfoComponent } from './components/contact-info/contact-info.component';
import { MapComponent } from './components/map/map.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { environment } from '../environments/environment';
import { NgwWowModule } from 'ngx-wow';
import { HttpClientModule } from '@angular/common/http';
import { RatingComponent } from './components/rating/rating.component';
import { GraphQLModule } from './graphql/graphql.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgxPageScrollCoreModule } from 'ngx-page-scroll-core';
import { NgxPageScrollModule } from 'ngx-page-scroll';
import { NgxMasonryModule } from 'ngx-masonry';
import { ContactFormComponent } from './forms/contact-form/contact-form.component';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';
import { ReactiveFormsModule } from '@angular/forms';
import { PhotosComponent } from './components/photos/photos.component';
import { SpecialFeaturesComponent } from './components/special-features/special-features.component';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { CookieService } from 'ngx-cookie-service';

@NgModule({
  declarations: [
    AboutComponent,
    AppComponent,
    CallToActionSectionComponent,
    ContactFormComponent,
    ContactInfoComponent,
    CustomerReviewsComponent,
    FooterComponent,
    HeaderComponent,
    MapComponent,
    MenuComponent,
    NavComponent,
    RatingComponent,
    PhotosComponent,
    SpecialFeaturesComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    HttpClientModule,
    BrowserAnimationsModule,
    CarouselModule,
    NgwWowModule,
    GraphQLModule,
    FontAwesomeModule,
    NgxPageScrollCoreModule,
    NgxPageScrollModule,
    NgxMasonryModule,
    RecaptchaV3Module,
    ReactiveFormsModule,
    SweetAlert2Module.forRoot()
  ],
  providers: [
    CookieService,
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useValue: environment.recaptchaV3_siteKey
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
