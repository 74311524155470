<form id="contactform" [formGroup]="contactForm" (ngSubmit)="executeRecaptcha()"
      class="form-group main-contact-form wow fadeIn" data-wow-delay="0.2s">
  <div class="form-row">
    <div class="col-12 col-md-4 mb-2">
      <label class="sr-only" for="name">Name</label>
      <input id="name" formControlName="name" class="form-control" placeholder="Your Name *"/>
      <div *ngIf="name.invalid && (name.dirty || name.touched)" class="text-danger">
        <div *ngIf="name.errors">
          Please enter your name.
        </div>
      </div>
    </div>
    <div class="col-12 col-md-4 mb-2">
      <label class="sr-only" for="email">Email</label>
      <input id="email" formControlName="email" class="form-control" placeholder="Your Email *"/>
      <div *ngIf="email.invalid && (email.dirty || email.touched)" class="text-danger">
        <div *ngIf="email.errors">
          Please enter your email address.
        </div>
      </div>
    </div>
    <div class="col-12 col-md-4 mb-2">
      <label class="sr-only" for="phone">Phone</label>
      <input id="phone" formControlName="phone" class="form-control" placeholder="Your Phone Number *"/>
      <div *ngIf="phone.invalid && (phone.dirty || phone.touched)" class="text-danger">
        <div *ngIf="phone.errors">
          Please enter your phone number.
        </div>
      </div>
    </div>
  </div>
  <div class="form-row">
    <div class="col">
      <label class="sr-only" for="message">Message</label>
      <textarea id="message" formControlName="message" name="comments" class="form-control" placeholder="Your Message *">
      </textarea>
      <div *ngIf="message.invalid && (message.dirty || message.touched)" class="text-danger">
        <div *ngIf="message.errors">
          Please enter a message.
        </div>
      </div>
    </div>
  </div>
  <div class="form-row">
    <div class="col">
      <input class="btn btn-primary btn-white mt30 pull-right" type="submit" name="submit"
             [disabled]="submitted || sendingData"
             value="Submit"/></div>
  </div>
</form>
