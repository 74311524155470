<section id="about" class="top-border-me">
  <div class="section-inner">

    <div class="container">
      <div class="row">
        <div class="col-lg-12 text-center mb100">
          <h2 class="section-heading">A <span class="theme-accent-color">Warm</span> Welcome</h2>
          <hr class="thin-hr">
          <h3 class="section-subheading secondary-font">We're very happy to see you.</h3>
        </div>
      </div>
    </div>

    <div>
      <div class="container">
        <div class="row">
          <div class="col-md-5 col-md-offset-1">
            <h2 class="mb50">About <span class="theme-accent-color">Peppers</span></h2>
            <p class="lead">Authentic Jamaican Jerk Cuisine in Grand Cayman.</p>
            <p>Enjoy our amazing BBQ jerk chicken, ribs, grilled steaks, burgers and fish, homemade pizzas, and much
              more! We are a favourite among locals and a 'must-go-to' place for visitors. We are open daily with
              entertainment on Thursdays, Fridays and Saturdays. We look forward to serving you!</p>
          </div>

          <div class="col-md-5">
            <h2 class="mb50">Latest <span class="theme-accent-color">Awards</span></h2>
            <p class="lead">We are highly recommended on Trip Advisor.</p>
            <div id="TA_certificateOfExcellence106" class="TA_certificateOfExcellence">
                  <ul id="2fU8rO2Y" class="TA_links wPXtqjBfD list-unstyled">
                    <li class="AXzM2raqZL7o">
                    </li>
                    <li id="ik8pE2sS" class="AXzM2raqZL7o">
                      <a target="_blank"
                         href="https://www.tripadvisor.com/Restaurant_Review-g147367-d6677222-Reviews-Pepper_s_Bar_and_Grill-Seven_Mile_Beach_Grand_Cayman_Cayman_Islands.html">
                        <img src="https://static.tacdn.com/img2/travelers_choice/widgets/tchotel_2022_L.png"
                             alt="TripAdvisor"
                             class="widCOEImg" id="CDSWIDCOELOGO"/>
                      </a>
                    </li>
                  </ul>
                </div>
                <script async
                        src="https://www.jscache.com/wejs?wtype=certificateOfExcellence&amp;uniq=106&amp;locationId=6677222&amp;lang=en_US&amp;year=2022&amp;display_version=2"
                        data-loadtrk onload="this.loadtrk=true"></script>
            <p class="mt-2">If you love our BBQ please take the time to vote.</p>
            <div class="row">
              <div class="col text-right">
                <a target="_blank" href="https://bestofcaymanislands.org/vote/">
                  <img
                    src="https://cdn.eliteinsights.io/bestofcaymanislands/wp-content/uploads/2024/02/Caymen_Islands_Logo_2024_Vote.png"
                    alt="Living.ky" width="100px"
                    class="mx-4"/>
                </a>
              </div>
            </div>


          </div>
        </div>
      </div>

    </div>

  </div>
</section>
