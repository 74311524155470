import gql from 'graphql-tag';

export const GET_COMPANY_INFO = {
  gql: gql`{
    companyInfo {
      phoneLine1
      addressLine1
      addressLine2
      postOfficeBox1
      sundayHours
      mondayHours
      tuesdayHours
      wednesdayHours
      thursdayHours
      fridayHours
      saturdayHours
      email
      google
      facebook
      instagram
      twitter
      foursquare
      tripadvisor
    }
  }`
};

export const GET_MENU = {
  gql: gql`{
    menus(active: true) {
      edges {
        node {
          name
          categories(active: true) {
            edges {
              node {
                igId
                name
                description
                items(active: true) {
                  edges {
                    node {
                      igId
                      description
                      image
                      name
                      price
                    }
                  }
                }
              }
            }
          }
          igId
        }
      }
    }
  }`
};

