<section id="events" class="dark-wrapper top-border-me opaqued parallax" data-parallax="scroll"
         [attr.data-image-src]="staticURL + 'assets/img/bg/bg4.jpg'" data-speed="0.8">
  <div class="section-inner">

    <div class="container">
      <div class="row">
        <div class="col-lg-12 text-center mb100">
          <h2 class="section-heading">Our <span class="theme-accent-color">Special</span> Features</h2>
          <hr class="thin-hr">
          <h3 class="section-subheading secondary-font">Try us for yourself today.</h3>
        </div>
      </div>
    </div>

    <div class="wow fadeIn" data-wow-delay="0.2s">
      <div class="container">
        <div class="row">
          <div class="col-xs-12" role="tabpanel">
            <div class="text-center">
              <!-- Nav tabs -->
              <ul class="nav nav-justified icon-tabs" id="nav-tabs" role="tablist">
                <li role="presentation" class="features border" id="feature-order-online">
                  <a href="#order-online" aria-controls="order-online" role="tab" data-toggle="tab"
                     (click)="setElementBorders('feature-order-online')" class="active">
                    <span class="tabtitle heading-font">Order Online</span>
                    <span class="tabtitle small"><span class="lead">Take-Out & Delivery</span></span>
                  </a>
                </li>
                <li role="presentation" class="features" id="feature-happy-hour">
                  <a href="#happy-hour" aria-controls="happy-hour" role="tab" data-toggle="tab"
                     (click)="setElementBorders('feature-happy-hour')">
                    <span class="tabtitle heading-font">Happy Hour</span>
                    <span class="tabtitle small lead"><span class="lead">Everyday 5PM-7PM</span></span>
                  </a>
                </li>
                <li role="presentation" class="features" id="feature-live-music">
                  <a href="#live-music" aria-controls="live-music" role="tab" data-toggle="tab"
                     (click)="setElementBorders('feature-live-music')">
                    <span class="tabtitle heading-font">Live Music</span>
                    <span class="tabtitle small"><span class="lead">Wed,Fri,Sat @ 8PM</span></span>
                  </a>
                </li>
<!--                <li role="presentation" class="features" id="feature-open-late">-->
<!--                  <a href="#open-late" aria-controls="open-late" role="tab" data-toggle="tab"-->
<!--                     (click)="setElementBorders('feature-open-late')">-->
<!--                    <span class="tabtitle heading-font">Open Late</span>-->
<!--                    <span class="tabtitle small"><span class="lead">Just Hangout</span></span>-->
<!--                  </a>-->
<!--                </li>-->
              </ul>
            </div>
            <div class="mt60">
              <!-- Tab panes -->
              <div class="tab-content" id="tabs-collapse">

                <div role="tabpanel" class="tab-pane fade in active show" id="order-online">
                  <div class="tab-inner">
                    <h2 class="secondary-font mb30">Local Delivery</h2>
                    <p>If you are too busy to dine-in, you can order for takeout and stop by to collect it, or if you
                      don’t feel like driving out you can also order online for delivery using local delivery apps!</p>

<!--                    <a class="btn btn-primary btn-red" href="https://islandgrub.ky/peppers">Order Online</a>-->
                  </div>
                </div>

                <div role="tabpanel" class="tab-pane fade" id="happy-hour">
                  <div class="tab-inner">
                    <h2 class="secondary-font mb30">Rush Hour or Happy Hour?</h2>
                    <p>Enjoy our everyday low pricing on drinks during Happy Hour.</p>
                  </div>
                </div>

                <div role="tabpanel" class="tab-pane fade" id="live-music">
                  <div class="tab-inner">
                    <h2 class="secondary-font mb30">Live music entertainment while you dine</h2>
                    <p>The hottest spot on the island, where you can enjoy some of the best live music and local
                      talent. </p>
                  </div>
                </div>

<!--                <div role="tabpanel" class="tab-pane fade" id="open-late">-->
<!--                  <div class="tab-inner">-->
<!--                    <h2 class="secondary-font mb30">You still can chill</h2>-->
<!--                    <p>Although our kitchen closes at 9pm (9pm on Sundays), it doesn’t mean that you have to leave right away.</p>-->
<!--                  </div>-->
<!--                </div>-->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
