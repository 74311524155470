import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'pbg-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.css']
})
export class MapComponent implements OnInit {

  lat = 19.318332;

  lng = -81.3846497;
  pin = {
    url: 'assets/img/marker.png',
    scaledSize: {
      width: 44,
      height: 44
    }
  };

  constructor() {
  }

  ngOnInit() {
  }

}
