import { AfterContentInit, Component, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';

declare let $: any;

@Component({
  selector: 'pbg-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit, AfterContentInit {

  staticURL = environment.staticURL;

  constructor() {
  }

  ngOnInit() {
    $('#headerwrap').backstretch([
      `${this.staticURL}assets/img/bg/bg18.jpg`,
      `${this.staticURL}assets/img/bg/bg16.jpeg`,
      `${this.staticURL}assets/img/bg/bg15.jpg`,
    ], {duration: 8000, fade: 500});

    /* Slide Sync to Carousel */
    $('.prev-bs-slide').on('click', (event) => {
      event.preventDefault();
      $('#headerwrap').data('backstretch').prev();
    });
    $('.next-bs-slide').on('click', (event) => {
      event.preventDefault();
      $('#headerwrap').data('backstretch').prev();
    });
  }

  ngAfterContentInit() {
  }

}
