import { Component, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'pbg-call-to-action-section',
  templateUrl: './call-to-action-section.component.html',
  styleUrls: ['./call-to-action-section.component.css']
})
export class CallToActionSectionComponent implements OnInit {

  staticURL = environment.staticURL;

  constructor() {
  }

  ngOnInit() {
  }

}
