<section id="menu" *ngIf="menus">
  <div class="section-inner">

    <div class="container">
      <div class="row">
        <div class="col-lg-12 text-center mb-2">
          <h2 class="section-heading">Browse <span class="theme-accent-color">The</span> Menu</h2>
          <hr class="thin-hr">
          <h3 class="section-subheading secondary-font">Your tastebuds will thank you.</h3>
        </div>
      </div>
    </div>

    <div class="container" *ngFor="let menu of menus;">
      <ngx-masonry [updateLayout]="updateMasonryLayout" [options]="options">
        <div class="row mb100">
          <div ngxMasonryItem class="col-md-4 wow fadeIn masonry-item"
               *ngFor="let menu_category of menu.node.categories.edges;">
            <h2 class="my-5">
              <span class="heading-font text-uppercase">{{ menu_category.node.name }}</span>
            </h2>
            <p>{{ menu_category.node.description }}</p>
            <ng-container *ngFor="let menu_item of menu_category.node.items.edges;">
              <h3>
                {{ menu_item.node.name }}
                <span class="theme-accent-color">
              {{ menu_item.node.price }}
            </span>
              </h3>
              <p>{{ menu_item.node.description }}</p>
            </ng-container>
          </div>
        </div>
      </ngx-masonry>
    </div>

    <div class="container">
      <div class="row">
        <div class="col text-center">
          <small>Please note 15% service charge will be added to all dine-in orders.</small>
        </div>
      </div>
      <div class="row">
        <div class="col text-center">
          <small>Menu prices and change in Cayman $</small>
        </div>
      </div>
      <div class="row mt-4">
        <div class="col text-center">
          <a class="btn btn-red" href="/menu">Download Menu</a>
        </div>
      </div>
    </div>
  </div>
</section>
