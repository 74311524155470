<!-- Navigation -->
<nav class="navbar navbar-expand-md navbar-default fixed-top fadeInDown" data-wow-delay="0.2s"
     [class.navbar-fixed-top]="!mobileSticky"
     [class.navbar-shrink]="shrinkMenu">
  <div class="container-fluid sm-p-0">
    <!-- Brand and toggle get grouped for better mobile display -->
    <div class="navbar-header page-scroll">
      <a class="navbar-brand smoothie" href="#">
        <img [src]="staticURL + 'assets/img/logo/peppers-logo-white.png'" [width]="logoWidth"
             alt="Pepper's Bar & Grill Logo">
      </a>
    </div>

    <button type="button" class="tcon tcon-menu--xcross navbar-toggler" data-toggle="collapse"
            data-target="#main-navigation" [class.tcon-transform]="menuActive" (click)="menuToggle($event)">
      <span class="tcon-menu__lines" aria-hidden="true"></span>
      <span class="tcon-visuallyhidden">toggle menu</span>
    </button>

    <!-- Collect the nav links, forms, and other content for toggling -->
    <div class="collapse navbar-collapse flex-grow-0" id="main-navigation" [class.menu-active]="menuActive">
      <ul class="nav navbar-nav navbar-right">
        <li class="my-auto">
          <a pageScroll href="#about" class="page-scroll">About Us</a>
        </li>
        <li class="my-auto">
          <a pageScroll href="#events" class="page-scroll">Events</a>
        </li>
        <li class="my-auto">
          <a pageScroll href="#menu" class="page-scroll">Our Menu</a>
        </li>
        <li class="my-auto">
          <a pageScroll href="#contact" class="page-scroll">Contact</a>
        </li>
        <li id="menu-trigger-wrapper" class="float-right d-none d-md-flex">
          <div class="pull-right">
            <button type="button" id="menu-toggle" class="tcon tcon-menu--xcross" aria-label="toggle menu"
                    (click)="menuToggle($event)" [class.tcon-transform]="menuActive">
              <span class="tcon-menu__lines" aria-hidden="true"></span>
              <span class="tcon-visuallyhidden">toggle menu</span>
            </button>
          </div>
        </li>
      </ul>
    </div>
    <!-- /.navbar-collapse -->
  </div>
  <!-- /.container-fluid -->
</nav>
