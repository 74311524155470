import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormsService } from '../../services/forms.service';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { Subscription } from 'rxjs';
import { AjaxResponse } from '../../interfaces/ajax-response';

declare let $: any;

@Component({
  selector: 'pbg-contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.css']
})
export class ContactFormComponent implements OnInit, OnDestroy {
  recentToken = '';

  success = false;

  error: boolean;

  submitted = false;

  contactForm: UntypedFormGroup;

  captchaExecutionSubscription: Subscription;

  sendingData = false;

  constructor(private formsService: FormsService, private recaptchaV3Service: ReCaptchaV3Service) {
  }

  ngOnInit() {
    this.contactForm = new UntypedFormGroup({
      name: new UntypedFormControl('', Validators.required),
      email: new UntypedFormControl('', [
        Validators.required,
        Validators.email
      ]),
      phone: new UntypedFormControl('', Validators.required),
      message: new UntypedFormControl('', Validators.required),
      'g-recaptcha-response': new UntypedFormControl(this.recentToken, Validators.required),
    });
  }

  executeRecaptcha(): void {
    if (this.captchaExecutionSubscription) {
      this.captchaExecutionSubscription.unsubscribe();
    }
    this.captchaExecutionSubscription = this.recaptchaV3Service.execute('submit_contact_form')
      .subscribe(
        (token) => this.recentToken = token,
        (err) => console.log(err),
        () => this.onSubmit()
      );
  }

  onSubmit() {
    this.contactForm.patchValue({'g-recaptcha-response': this.recentToken});

    if (this.contactForm.valid) {
      this.sendingData = true;

      this.formsService.sendContactForm(this.contactForm.value)
        .subscribe((data: AjaxResponse) => {
          this.success = data.success;
          this.error = !data.success;
          this.sendingData = false;
          this.onSubmitted(data);
        });
    }
  }

  onSubmitted(data: AjaxResponse) {

    if (data.success) {
      this.submitted = true;
    } else {
      $('#message').addClass('text-danger');
    }

    document.getElementById('message').innerHTML = data.message;
    $('#message').slideDown('slow');
    $(window).trigger('resize');
  }

  get name() {
    return this.contactForm.get('name');
  }

  get phone() {
    return this.contactForm.get('phone');
  }

  get email() {
    return this.contactForm.get('email');
  }

  get message() {
    return this.contactForm.get('message');
  }

  public ngOnDestroy() {
    if (this.captchaExecutionSubscription) {
      this.captchaExecutionSubscription.unsubscribe();
    }
  }
}
