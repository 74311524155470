import { Component, Input, OnInit } from '@angular/core';
import { CompanyInfoNode } from '../../../generated/graphql';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'pbg-contact-info',
  templateUrl: './contact-info.component.html',
  styleUrls: ['./contact-info.component.css']
})
export class ContactInfoComponent implements OnInit {

  staticURL = environment.staticURL;

  @Input() companyInfo: CompanyInfoNode;

  constructor() { }

  ngOnInit() {
  }

}
