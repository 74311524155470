import {NgModule} from '@angular/core';
import {HttpClientModule} from '@angular/common/http';
import {HttpHeaders} from '@angular/common/http';

import { environment } from '../../environments/environment';

// Apollo
import {ApolloModule, Apollo} from 'apollo-angular';
import { InMemoryCache } from '@apollo/client/core';
import { CookieService } from 'ngx-cookie-service';
import { HttpLink } from 'apollo-angular/http';

const uri = environment.graphqlEndpoint;

@NgModule({
    exports: [
        HttpClientModule,
        ApolloModule,
    ]
})
export class GraphQLModule {
    constructor(
        apollo: Apollo,
        httpLink: HttpLink,
        private cookieService: CookieService
    ) {
        const http = httpLink.create({
            uri: uri,
            headers: new HttpHeaders({
                credentials: 'include',
                'X-CSRFToken': cookieService.get('csrftoken'),
            }),
            withCredentials: true
        });

        // create Apollo
        apollo.create({
            link: http,
            cache: new InMemoryCache(),
            connectToDevTools: environment.connectGQLDevTools,
            defaultOptions: {
                watchQuery: {
                    errorPolicy: 'all'
                }
            }
        });
    }
}
