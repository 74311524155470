<section id="contact" class="dark-wrapper opaqued parallax" data-parallax="scroll"
         [attr.data-image-src]="staticURL + 'assets/img/bg/bg1.jpg'"
         data-speed="0.8">
  <div class="section-inner">
    <div class="container">
      <div class="row">
        <div class="col-lg-12 text-center mb-3">
          <h2 class="section-heading">Contact <span class="theme-accent-color">Us</span></h2>
          <hr class="thin-hr">
          <h3 class="section-subheading secondary-font">We look forward to seeing you soon.....</h3>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row mb-3">
        <div class="col-10 mx-auto">
          <p>Peppers only takes reservations for large groups of 9 to 25 persons as long as the group can arrive before
            6.30pm. All other customers are seated on a first come first serve basis. If you need to make a
            reservation for a large group please call <a href="tel:+13459433000">(345) 943-3000</a> or email
            <a href="mailto:info@peppers.ky">info@peppers.ky</a> in advance.</p>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row mb100">
        <!-- Address, Phone & Email -->
        <div class="col-md-5 offset-md-1 wow fadeIn">
          <h3 class="mb30">Address</h3>
          <p class="lead">{{ companyInfo?.addressLine1 }}<br>
            {{ companyInfo?.addressLine2 }}<br></p>
          <p class="lead"><a [href]="'tel:' + companyInfo?.phoneLine1">{{ companyInfo?.phoneLine1 }}</a><br></p>
          <p class="lead"><a [href]="'mailto:' + companyInfo?.email">{{ companyInfo?.email }}</a><br></p>
        </div>

        <div class="col-md-5 col-sm-7 wow fadeIn">
          <h3 class="mb30">Opening Times</h3>
          <div class="row">
            <div class="col-5">
              <ul class="list-unstyled weekdays">
                <li>Monday</li>
                <li>Tuesday</li>
                <li>Wednesday</li>
                <li>Thursday</li>
                <li>Friday</li>
                <li>Saturday</li>
                <li>Sunday</li>
              </ul>
            </div>
            <div class="col-7">
              <ul class="list-unstyled">
                <li>{{ companyInfo?.mondayHours }}</li>
                <li>{{ companyInfo?.tuesdayHours }}</li>
                <li>{{ companyInfo?.wednesdayHours }}</li>
                <li>{{ companyInfo?.thursdayHours }}</li>
                <li>{{ companyInfo?.fridayHours }}</li>
                <li>{{ companyInfo?.saturdayHours }}</li>
                <li>{{ companyInfo?.sundayHours }}</li>
              </ul>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col">
              <p>Now OPEN on Public Holidays</p>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-10 offset-md-1">
          <div class="row">
            <div id="message" class="col-md-12 font-italic font-weight-bolder"></div>
            <div class="col-md-12">
              <pbg-contact-form></pbg-contact-form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
